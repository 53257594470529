/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
footer {
  margin-top: 120px;
  background-color: #01689b;
}

.footer__content {
  padding-top: 45px;
  padding-bottom: 90px;
  color: #FFFFFF;
  max-width: 1183px;
  padding-left: 15px;
  padding-right: 15px;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}

.footer__payoff {
  flex: 0 0 50%;
  font: 32px/100% "RO Serif";
  font-style: italic;
}

.footer__linkscolumn1,
.footer__linkscolumn2 {
  flex: 1 1 0;
}

.footer__linkscolumn__header {
  font: 26px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  margin-bottom: 15px;
}

.footer__top__quicklinks__menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__top__quicklinks__menu a {
  font: 18px/24px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  color: #FFFFFF;
  text-decoration: none;
}

.footer__top__quicklinks__menu a:hover {
  text-decoration: underline;
}

.footer__linkscolumn1.footer__linkscolumn1 a,
.footer__linkscolumn2.footer__linkscolumn2 a {
  display: block;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
}

.footer__linkscolumn1.footer__linkscolumn1 a::before,
.footer__linkscolumn2.footer__linkscolumn2 a::before {
  flex: none;
  content: "\ea25";
  font-family: "RO Icons";
  font-size: 15px;
  font-size: 11px;
  margin-right: 12px;
  color: #FFFFFF;
}

@media (max-width: 1023px) {
  .footer__content {
    padding-top: 25px;
    padding-bottom: 35px;
  }
  .footer__payoff {
    flex: 0 0 100%;
    margin-bottom: 30px;
    font-size: 18px;
  }
  .footer__linkscolumn__header {
    font: 18px/23px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
    margin-bottom: 8px;
  }
  .footer__top__quicklinks__menu a {
    font: 14px/24px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  }
  .footer__linkscolumn1.footer__linkscolumn1 a,
  .footer__linkscolumn2.footer__linkscolumn2 a {
    display: block;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
@media (max-width: 414px) {
  .footer__content {
    display: block;
  }
  .footer__linkscolumn2 {
    margin-top: 20px;
  }
  .footer__linkscolumn__header {
    font: 18px/23px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
    margin-bottom: 3px;
  }
}
@media (min-width: 415px) and (max-width: 1023px) {
  .footer__linkscolumn2 {
    margin-left: 30px;
  }
}
@media (min-width: 1024px) {
  .footer__linkscolumn1,
  .footer__linkscolumn2 {
    margin-left: 30px;
  }
}