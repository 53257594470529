/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
:root {
  font: 18px/24px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  --sidepadding: 15px;
}

@media (max-width: 414px) {
  :root {
    --sidepadding: 10px;
  }
}
html {
  background-color: #ffffff;
}

/* Stretch page and push footer to end of viewport or page ******************************/
html {
  /* we cannot use a min-height on flex in IE,
     but we can set a fixed 100vh height here and by making it
     flex have body inherit the height but allow it to overflow (but not shrink) using 1 0 auto;
  */
  display: flex;
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: visible;
}

body > * {
  flex: none;
}

main#content-wrapper {
  flex: 1 0 auto;
}

/*****************************************************************************************/
.header__logolink {
  margin-left: auto;
  margin-right: auto;
  display: table;
  margin-bottom: 25px;
  position: relative;
  left: 46px;
}

.header__logo {
  display: block;
  width: 146px;
  width: clamp(122px, 22vw, 146px);
}

.header__title,
.header__doccount,
.backlinkbar {
  height: 75px;
  height: clamp(42px, 7vw, 75px);
  display: flex;
  align-items: center;
}

.header__title,
.header__doccount,
.backlinkbar {
  color: #01496d;
}

.backlinkbar {
  background-color: #F3F3F3;
}

.backlinkbar__content {
  max-width: 1183px;
  padding-left: 15px;
  padding-right: 15px;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.backlink__link {
  font: 20px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  color: #01496d;
  text-decoration: none;
}

.backlink__link::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f053";
  font-size: 14px;
  margin-right: 20px;
  color: #01496d;
}

.backlink__link:hover {
  text-decoration: none;
}

@media (max-width: 414px) {
  .backlink__link {
    font-size: 14px;
  }
  .backlink__link::before {
    font-size: 12px;
  }
  h1.publication__title {
    font: bold 18px/22px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.content-wrapper--rtddoc,
main > .wh-form {
  max-width: 783px;
  padding-left: 15px;
  padding-right: 15px;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

main.content-wrapper--rtddoc > *:first-child,
main > .wh-form:first-child {
  margin-top: 30px;
}

.header__title {
  background-color: #01689b;
}

.header__title__content {
  max-width: 1183px;
  padding-left: 15px;
  padding-right: 15px;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  position: relative;
}

.header__title__content__title {
  /*
  WOBCOVID19
  font: bold 33px $font-heading;
  font-size: clamp(18px, 4.7vw, 33px);
  */
  font: bold 26px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  font-size: clamp(14px, 3.7vw, 26px);
  line-height: 100%;
  text-decoration: none;
  color: #FFFFFF;
}

.header__search {
  position: absolute;
  right: 15px;
  width: 609px;
  max-width: calc(100% - 30px);
  display: flex;
}

.header__search__input.header__search__input.header__search__input,
.header__search__submit.header__search__submit.header__search__submit {
  height: 48px;
  max-height: calc(clamp(42px, 7vw, 75px) - 6px);
}

.header__search__clearfieldwrapper {
  flex: 1 1 0px;
}

.header__search__input {
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s;
}

.header__search__submit {
  flex: none;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  width: 48px;
  font-size: 20px;
  transition: background-color 0.5s;
  cursor: pointer;
}

.header__search__submit::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f002";
}

@media (max-width: 799px) {
  .header__search__submit {
    background-color: transparent;
  }
  .header__search__submit::before {
    color: #FFFFFF;
  }
}
@media (min-width: 800px) {
  .header__search__submit {
    background-color: #FFFFFF;
  }
  .header__search__submit::before {
    color: #01689b;
  }
}
html.headersearch--active .header__search__input {
  opacity: 1;
}
html.headersearch--active .header__search__submit {
  background-color: #FFFFFF;
  border: 2px solid #FFFFFF;
}
html.headersearch--active .header__search__submit::before {
  color: #01689b;
}

.header__doccount {
  background-color: #BCD7E5;
  color: #01689b;
}

.header__doccount__content {
  max-width: 1183px;
  padding-left: 15px;
  padding-right: 15px;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /*
  WOBCOVID19
  font: 20px $font-text;
  font-size: clamp(14px, 3vw, 20px);
  */
  font: 20px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  font-size: clamp(12px, 2.5vw, 20px);
  line-height: 100%;
}

.header__doccount__number {
  /*
  WOBCOVID19
  font: bold 30px $font-heading;
  font-size: clamp(14px, 3.6vw, 30px);
  */
  font: bold 30px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  font-size: clamp(12px, 3.6vw, 30px);
  line-height: 100%;
}

@media (max-width: 414px) {
  .header__title__content {
    font-size: 18px;
  }
  .header__doccount,
  .header__doccount__number {
    font-size: 12.5px;
  }
}
.assistive {
  position: absolute !important;
  display: inline-block !important;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

button.skiplinks {
  position: absolute;
  left: 50px;
  top: 25px;
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 0 16px;
  min-height: 44px;
  color: #FFFFFF;
  background-color: #01689b;
  text-decoration: none;
  font-size: 16px;
}