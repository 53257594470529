@font-face {
  font-family: "RO Serif";
  font-weight: normal;
  font-style: normal;
  src: url("ro-serifweb-regular.woff2") format("woff2"), url("ro-serifweb-regular.woff") format("woff");
}

@font-face {
  font-family: "RO Serif";
  font-weight: normal;
  font-style: italic;
  src: url("ro-serifweb-italic.woff2") format("woff2"), url("ro-serifweb-italic.woff") format("woff");
}
