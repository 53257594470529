/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
.publication__title {
  margin-top: 40px;
  margin-bottom: 40px;
  font: bold 32px/40px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
}

/*
.publication__description > *
{
  font: 26px/36px $font-text;
}
*/
.publication__downloads {
  margin-top: 30px;
}

.publication__download {
  display: block;
  padding: 15px;
  background-color: #F3F3F3;
  text-decoration: none;
}

.publication__download + .publication__download {
  margin-top: 22px;
}

.publication__download:hover h2 {
  color: #01496d;
}

.publication__download:hover h2 span {
  text-decoration: underline;
}

.publication__download h2 {
  color: #01689b;
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 20px;
}

.publication__download h2 em {
  flex: none;
  margin-left: 30px;
}

.publication__download .meta__line {
  display: block;
  color: #535353;
}

.publication__title,
.publication__description,
.publication__downloads,
.publication__meta {
  max-width: 783px;
  padding-left: 15px;
  padding-right: 15px;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.publication__description > *:first-child {
  margin-top: 0;
}

.publication__meta {
  margin-top: 60px;
}

.publication__download h2 {
  font: bold 26px/32px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  word-break: break-word;
  /*
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  */
}

/* Tablet */
@media (min-width: 415px) and (max-width: 800px) {
  .publication__download h2 {
    margin-bottom: 10px;
  }
  .publication__download .meta__line {
    font: 18px/24px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  }
}
/* Mobile */
@media (max-width: 414px) {
  .publication__downloads {
    padding-left: 0;
    padding-right: 0;
  }
  .publication__download {
    max-width: 783px;
    padding-left: 15px;
    padding-right: 15px;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .publication__download + .publication__download {
    margin-top: 12px;
  }
  .publication__download h2 {
    font: bold 16px/22px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
    margin-bottom: 10px;
  }
  .publication__download .meta__line {
    font: 14px/18px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  }
}
.publication__meta__departments h2 {
  margin-bottom: 15px;
}

.publication__meta__department {
  display: flex;
  align-items: baseline;
  width: max-content;
  max-width: 100%;
  font: 20px/28px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  text-decoration: none;
}

.publication__meta__department::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f054";
  font-size: 14px;
  margin-right: 13px;
  position: relative;
  top: -2px;
}

a.publication__meta__department {
  color: #01689b;
}

a.publication__meta__department:hover {
  text-decoration: underline;
}