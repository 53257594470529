/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
/*
$button-textcolor: #FFFFFF; //$color-text-dark;
$button_disabled_backgroundcolor: #AAAAAA;
$button_disabled_textcolor:       #666666;
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
/*
$button-textcolor: #FFFFFF; //$color-text-dark;
$button_disabled_backgroundcolor: #AAAAAA;
$button_disabled_textcolor:       #666666;
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
fieldset.multiselect {
  border: 0;
  padding: 0;
  margin: 0;
}

fieldset.multiselect > legend {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

.multiselect {
  display: flex;
  flex-direction: column;
}

.multiselect__closetrigger {
  position: absolute;
  z-index: -99;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  width: 0 !important;
  height: 0 !important;
}

.multiselect__closetrigger:focus {
  outline: none;
}

.multiselect__emptytext--hidden {
  display: none;
}

.multiselect__valuedisplay.multiselect__valuedisplay.multiselect__valuedisplay {
  position: relative;
  left: 0;
  display: block;
  width: 100%;
  max-width: none;
  height: 48px;
  flex: none;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  white-space: nowrap;
  border-color: #999999 !important;
  /*
    .fa-plus-circle
    {
      color: #999999;
    }
  */
  overflow: hidden;
  text-overflow: ellipsis;
  /* The SVG image needs preserveAspectRatio="xMaxYMid" to get positioned at the right in IE10/11 (and might also need the viewBox) */
  /*
  Up & Down arrow
  background: #FFFFFF url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNYXhZTWlkIiB2aWV3Qm94PSIwIDAgNC45NSAxMCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fS5jbHMtMntmaWxsOiM0NDQ7fTwvc3R5bGU+PC9kZWZzPjxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjQuOTUiIGhlaWdodD0iMTAiLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMS40MSA0LjY3IDIuNDggMy4xOCAzLjU0IDQuNjcgMS40MSA0LjY3Ii8+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjMuNTQgNS4zMyAyLjQ4IDYuODIgMS40MSA1LjMzIDMuNTQgNS4zMyIvPjwvc3ZnPg==) no-repeat calc(100% - 10px) 50%;
  */
  /*
  FontAwesome angle down (SOLID?):
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'/%3E%3C/svg%3E");
  background-size: 23px 28px;
  */
  background: #FFFFFF url("data:image/svg+xml,%3Csvg height='192' viewBox='0 0 322 192' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2301689b' d='m152.5 187.8-148-146.8c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0l119.7 118.5 119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'/%3E%3C/svg%3E") no-repeat calc(100% - 17px) calc(50% + 2px);
}
@media (max-width: 500px) {
  .multiselect__valuedisplay.multiselect__valuedisplay.multiselect__valuedisplay {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.multiselect__valuedisplay__title {
  display: inline-block;
  flex: 1 0 0px;
  display: inline-block;
  line-height: 44px;
}

.multiselect__valuedisplay svg {
  flex: none;
  width: 22px;
}

.multiselect svg > * {
  fill: #444444;
}

.multiselect:focus-within svg > * {
  fill: #000000;
}

.multiselect__panel__trigger {
  user-select: none;
}

.multiselect__panel__trigger .far {
  margin-left: auto;
  font-size: 15px;
}

.multiselect__panel__container {
  opacity: 0;
  pointer-events: none;
}

.multiselect__panel:focus {
  outline: 0;
}

.multiselect__panel__trigger::after {
  display: none;
}

.multiselect__panel__trigger:focus ~ .multiselect__panel__container {
  opacity: 1;
  pointer-events: auto;
}

.multiselect__panel__trigger:focus ~ .multiselect__panel__container .multiselect__panel {
  margin-bottom: 100px;
}

.multiselect__panel__trigger.focus-within ~ .multiselect__panel__container {
  opacity: 1;
  pointer-events: auto;
}

.multiselect__panel__trigger.focus-within ~ .multiselect__panel__container .multiselect__panel {
  margin-bottom: 100px;
}

.multiselect__panel__container:focus-within {
  opacity: 1;
  pointer-events: auto;
}

.multiselect__panel__container:focus-within .multiselect__panel {
  margin-bottom: 100px;
}

.multiselect__panel__container.focus-within {
  opacity: 1;
  pointer-events: auto;
}

.multiselect__panel__container.focus-within .multiselect__panel {
  margin-bottom: 100px;
}

/*
.praktijkvoorbeelden__filters .multiselect__panel
{
  margin-bottom: 100px;
}
*/
.multiselect__panel__search {
  display: flex;
  align-items: center;
  margin: 4px;
  position: relative;
}

.multiselect__panel__search input {
  background-color: #F0F0F0;
  border-radius: 4px;
  border: 0;
  width: 100%;
}
.multiselect__panel__search input:focus {
  background-color: #E7E7E7;
  outline: 0;
}
.multiselect__panel__search input::placeholder {
  color: #C5C5C5;
  opacity: 1; /* Firefox */
}
.multiselect__panel__search input:-ms-input-placeholder {
  color: #C5C5C5;
}
.multiselect__panel__search input::-ms-input-placeholder {
  color: #C5C5C5;
}
.multiselect__panel__search input:focus::placeholder {
  color: #999999;
  opacity: 1; /* Firefox */
}
.multiselect__panel__search input:focus:-ms-input-placeholder {
  color: #999999;
}
.multiselect__panel__search input:focus::-ms-input-placeholder {
  color: #999999;
}

.multiselect__panel__search .far {
  position: absolute;
  right: 15px;
  font-size: 15px;
}

.multiselect__panel__items {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.multiselect__panel__item {
  display: flex;
}

.multiselect__panel__item input {
  flex: none;
}

.multiselect__panel__item > label + label {
  flex: 1 0 0;
}

.multiselect__panel__item > label:hover + label,
.multiselect__panel__item > label + label:hover {
  color: #01689b;
  cursor: pointer;
}

.multiselect__panel__buttonbar {
  border-top: 1px solid #EEEEEE;
  display: flex;
  align-items: center;
  padding-right: 19px;
  padding-left: 19px;
  background-color: #F3F3F3;
  height: 70px;
}

/*
.multiselect__panel__cancel
{
  @include clearbuttonelementstyling;
  @include buttonlink;
  height: $controls_height; // same height as searchfield for visual balance
  color: #888888;
  font-weight: 600;
}
*/
.multiselect__panel__clear {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  font: inherit;
}

.multiselect__panel__clear,
.multiselect__panel__ok {
  background-color: #01689b;
  color: #FFFFFF;
  border: 0;
  height: 48px;
  display: flex;
  align-items: center;
  transition: background-color 0.25s;
  color: #FFFFFF;
  border-radius: 2px;
  border-radius: 2px;
  padding-left: 33px;
  padding-right: 33px;
  cursor: pointer;
}
.multiselect__panel__clear:hover,
.multiselect__panel__ok:hover {
  background-color: #01496d;
  color: #FFFFFF;
  text-decoration: none;
}
.multiselect__panel__clear .wh-form__button--disabled,
.multiselect__panel__ok .wh-form__button--disabled {
  background-color: #999999;
  color: #FFFFFF;
  cursor: default;
}
@media (max-width: 800px) {
  .multiselect__panel__clear,
  .multiselect__panel__ok {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.multiselect__panel__ok {
  margin-left: 25px;
}

/*
@media (max-width: 640px)
{
  @include multiselect-fullscreen;
}

@media (min-width: 640px)
{
  @include multiselect-inline;
}
*/
.multiselect__panel__container {
  position: relative;
}

.multiselect__panel {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.multiselect__panel {
  width: 100%;
  min-width: 300px;
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.63);
  border-radius: 4px;
}

/*
  .multiselect__panel__items
  {
    max-height: 350px;
  }
*/
.multiselect__hidden {
  display: none;
}