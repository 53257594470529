/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
/*
$button-textcolor: #FFFFFF; //$color-text-dark;
$button_disabled_backgroundcolor: #AAAAAA;
$button_disabled_textcolor:       #666666;
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

:-ms-input-placeholder {
  opacity: 1;
  color: #999999;
}

input::placeholder {
  opacity: 1;
  color: #999999;
}

:disabled:-ms-input-placeholder, [data-wh-form-disabled]:-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}
:disabled input::placeholder, [data-wh-form-disabled] input::placeholder {
  opacity: 1;
  color: inherit;
}

input[type=text],
input[type=url],
input[type=email],
input[type=password],
input[type=number],
input[type=date],
input[type=search],
.wh-form__dateinputgroup,
.wh-form__timeinputgroup,
textarea,
select:not(.wh-rtd__toolbarstyle),
.multiselect__panel__trigger {
  padding-left: 15px;
  padding-right: 15px;
  font: 20px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  height: 48px;
  border: 1px solid #979797;
  border-radius: 0px;
  background-color: #FFFFFF;
  color: #282828;
}
@media (max-width: 500px) {
  input[type=text],
  input[type=url],
  input[type=email],
  input[type=password],
  input[type=number],
  input[type=date],
  input[type=search],
  .wh-form__dateinputgroup,
  .wh-form__timeinputgroup,
  textarea,
  select:not(.wh-rtd__toolbarstyle),
  .multiselect__panel__trigger {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 500px) {
  input[type=text],
  input[type=url],
  input[type=email],
  input[type=password],
  input[type=number],
  input[type=date],
  input[type=search],
  .wh-form__dateinputgroup,
  .wh-form__timeinputgroup,
  textarea,
  select:not(.wh-rtd__toolbarstyle),
  .multiselect__panel__trigger {
    font: 16px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
    height: 44px;
  }
}
input[type=text]:focus,
input[type=url]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=date]:focus,
input[type=search]:focus,
.wh-form__dateinputgroup:focus,
.wh-form__timeinputgroup:focus,
textarea:focus,
select:not(.wh-rtd__toolbarstyle):focus,
.multiselect__panel__trigger:focus {
  border-color: #0067B4;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15) !important;
}
input[type=text]:disabled, input[type=text][data-wh-form-disabled],
input[type=url]:disabled,
input[type=url][data-wh-form-disabled],
input[type=email]:disabled,
input[type=email][data-wh-form-disabled],
input[type=password]:disabled,
input[type=password][data-wh-form-disabled],
input[type=number]:disabled,
input[type=number][data-wh-form-disabled],
input[type=date]:disabled,
input[type=date][data-wh-form-disabled],
input[type=search]:disabled,
input[type=search][data-wh-form-disabled],
.wh-form__dateinputgroup:disabled,
.wh-form__dateinputgroup[data-wh-form-disabled],
.wh-form__timeinputgroup:disabled,
.wh-form__timeinputgroup[data-wh-form-disabled],
textarea:disabled,
textarea[data-wh-form-disabled],
select:not(.wh-rtd__toolbarstyle):disabled,
select:not(.wh-rtd__toolbarstyle)[data-wh-form-disabled],
.multiselect__panel__trigger:disabled,
.multiselect__panel__trigger[data-wh-form-disabled] {
  color: #282828;
  border-color: #C5C5C5;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: none;
}

textarea {
  flex: 1 0 0px;
  padding-right: 15px;
}

.wh-form__imgedit {
  border: 1px solid #979797;
  border-radius: 0px;
  background-color: #FFFFFF;
  color: #282828;
}
.wh-form__imgedit:focus {
  border-color: #0067B4;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15) !important;
}
.wh-form__imgedit:disabled, .wh-form__imgedit[data-wh-form-disabled] {
  color: #282828;
  border-color: #C5C5C5;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: none;
}

.wh-form__rtd:focus-within {
  border-color: #0067B4;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15) !important;
}

.wh-form__dateinputgroup:focus-within,
.wh-form__timeinputgroup:focus-within {
  border-color: #0067B4;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15) !important;
}

.wh-form__dateinputgroup__line.wh-form__dateinputgroup__line input:focus,
.wh-form__timeinputgroup__line.wh-form__timeinputgroup__line input:focus {
  border: 0;
  outline: 0;
  box-shadow: none;
  background-color: #F0F0F0;
}