@import url("../../web/fonts/fontawesome/css/all.css");
@import url("../../web/fonts/rosans/rosans.css");
@import url("../../web/fonts/roserif/roserif.css");
@import url("../../web/fonts/roicons/roicons.css");


html
{
  font-family: "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;

  font-size: 100%;
  -webkit-text-size-adjust: 100%;

  overflow-y: scroll;

  color: #000000;
}

body
{
  background-color: #FFFFFF; /* required for WCAG compliance */
  color: #000000; /* required for WCAG compliance */
}

  h1
, h2
, h3
, .style_as_h1
, .wh-form__grouptitle
, .widget__title
{
  font-family: "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
  font-weight: bold;
}

h1
, .style_as_h1
{
  font-weight: bold;
  font-size: 44px;
  line-height: 48px;
  margin: 0;
}
.page__contentarea--formwebtool h1
{
  color: #56145F;
}


  .page__body h1.page-header__title
, .page__body .pageheader__date
{
  color: #56145F;
}

/* KEEP IN SYNC WITH h2_styling mixin (/shared/config.scss)
   FIXME: ook bij dit webdesign?
*/
h2
, .widget__title
{
  font: bold 28px/32px "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
  color: #000000;
  margin: 0;
}

/* same size as paragraphs, but bold and heading font
   KEEP IN SYNC WITH h3_styling mixin (/shared/config.scss)
*/
  h3
, .style_as_h3
, .wh-form__grouptitle
, .widget-accordion__item__header
{
  font-size: 23px;
  line-height: 28px;

  font-weight: 500; /* medium */
  margin: 0;
}

h3, .style_as_h3
{
  color: #000000;
}

  p.normal
, p.normal_small
, p.intro
, p.intro_attention
, p.attention
, p.subtitle
, p.credits
, ol.ordered
, ul.unordered
{
  color: #000000;
  margin: 0;
}

  p.normal
, p.attention
, ol.ordered
, ul.unordered
, .widget-linkslist
{
  font-size: 20px;
  line-height: 28px;
}

p.normal_small
{
  font: 18px/24px "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
}

  p.intro
, p.intro_attention
{
  font: 26px/36px "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
}

  p.attention
, p.intro_attention
{
  border-left: 10px solid #01689b;
  padding-left: 28px;
}

/*
p.subtitle
{
  font: 16px/24px "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
}


p.credits
{
  font: 15px/18px "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
}
*/


.publication__download h2
{
  font: 26px/32px "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
}

@media (max-width: 800px)
{
  h1
  {
    font: bold 24px/28px "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
  }

  h2
  , .widget__title
  {
    font: bold 21px/24px "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
  }

  /* same size as paragraphs, but bold and heading font
     KEEP IN SYNC WITH h3_styling mixin (/shared/config.scss)
  */
    h3
  , .style_as_h3
  , .wh-form__grouptitle
  , .widget-accordion__item__header
  {
    font: 500 20px/24px "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
  }

    p.normal
  , p.attention
  , ol.ordered
  , ul.unordered
  , .widget-linkslist
  /*, .searchresult__description*/
  {
    font-size: 18px;
    line-height: 24px;
    /*color: #333333;*/
  }

  p.normal_small
  {
    font: 18px/24px "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
  }

    p.intro
  , p.intro_attention
  {
    font: 22px/30px "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
  }
}




/* Mobile */
@media (max-width: 375px)
{
  h1
  {
    font: bold 18px/2px "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
  }

  h2
  , .widget__title
  {
    font: bold 16px/24px "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
  }

  /* same size as paragraphs, but bold and heading font
     KEEP IN SYNC WITH h3_styling mixin (/shared/config.scss)
  */
    h3
  , .style_as_h3
  , .wh-form__grouptitle
  , .widget-accordion__item__header
  {
    font: 500 14px/24px "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
  }

    p.normal
  , p.attention
  , ol.ordered
  , ul.unordered
  , .widget-linkslist
  , .publication__download h2
  {
    font-size: 16px;
    line-height: 22px;
  }

  p.normal_small
  {
    font: 16px/22px "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
  }

    p.intro
  , p.intro_attention
  {
    font: 16px/22px "RO Sans", "Alegreya Sans", Calibri, Sans-Serif;
  }
}





a
{
  background-color: transparent; /* needed to comply to WCAG ? */
  /*color: #56145F;*/
}

b
{
  font-weight: 600; /* use semi-bold instead of bold */
}


sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

/* lists */
  ul.unordered
, ol.ordered
{
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
  margin-left: 0;
  line-height: 160%;
}

/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #000000;
}
.wh-rtd__table .wh-rtd--hascolheader > *
{
  border-bottom: 2px solid #000000;
}





  .wh-video a
, p.normal a
, p.intro a
, p.attention a
, ul.unordered a
, ol.ordered a
, h2.heading2 a
, h3.heading3 a
, .page__contentarea--formwebtool a
{
  /*color: inherit;*/
  color: #01689b;
  text-decoration: underline;
}


  .wh-video a:hover
, p.normal a:hover
, p.normal_small a:hover
, p.credits a:hover
, ul.unordered a:hover
, ol.ordered a:hover
, h2.heading2 a:hover
, h3.heading3 a:hover
{
  text-decoration: underline;
}



/* RTD vertical whitespace ***********************************************************/

.widget
{
  margin-top: 50px;
}

h2.heading2
{
  margin-top: 50px;
}

h3.heading3
{
  margin-top: 35px;
}

  .wh-video
, p.normal
, p.attention
, ul.unordered
, ol.ordered
{
  /* when changing this value also change $paragraph_space_between in config.scss */
  margin-top: 30px; /* $rtdcontent_vspace */
}

  p.attention + p.attention
, p.intro_attention + p.intro_attention
{
  margin-top: 18px;
}


  .widget-button
, h2.heading2 + .widget
, h3.heading3 + .widget
, p.credits
, p.subtitle
{
  margin-top: 15px;
}

  h2.heading2 + .wh-video
, h2.heading2 + p.intro
, h2.heading2 + p.attention
, h2.heading2 + p.intro_attention
, h2.heading2 + p.normal
, h2.heading2 + ul.unordered
, h2.heading2 + ol.ordered
, h3.heading3 + .wh-video
, h3.heading3 + p.intro
, h3.heading3 + p.attention
, h3.heading2 + p.intro_attention
, h3.heading3 + p.normal
, h3.heading3 + ul.unordered
, h3.heading3 + ol.ordered
{
  margin-top: 10px;
}

h1.heading1
{
  margin-top: 30px;
}

h1.heading1 + *
{
  margin-top: 30px;
}


p + .widget-linkslist
{
  margin-top: 0;
}

ul li::marker
{
  color: #01689b;
  font-size: 12px;
}





/* Mobile whitespace */
@media (max-width: 640px)
{
  h1
  {
    margin-bottom: 15px;
  }

  .widget
  {
    margin-top: 30px; /* instead of 50px */
  }

  h2.heading2
  {
    margin-top: 35px; /* instead of 50px */
  }

  h3.heading3
  {
    margin-top: 25px; /* instead of 35px */
  }

    .wh-video
  , p.normal
  {
    /* when changing this value also change $paragraph_space_between in config.scss */
    margin-top: 20px; /* instead of 30px */
  }

    .widget-button
  , h2.heading2 + .widget
  , h3.heading3 + .widget
  , p.credits
  , p.subtitle
  {
    margin-top: 15px;
  }

    h2.heading2 + p.normal
  , h3.heading3 + p.normal
  {
    margin-top: 10px;
  }

    ul.unordered
  , ol.ordered
  , p + .widget-linkslist
  {
    margin-top: 0;
  }
}




/* any RTD content (descriptions, column content) */
  .wh-rtd > *:first-child
, .rtdcontent > *:first-child
, .wh-form__richtext > *:first-child
{
  margin-top: 0;
}
  .wh-rtd > *:last-child
, .rtdcontent > *:last-child
, .wh-form__richtext > *:last-child
{
  margin-bottom: 0;
}

.page__contentarea--rtddoc > *:first-child
{
  margin-top: 30px;
}
