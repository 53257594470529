/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
/*
$button-textcolor: #FFFFFF; //$color-text-dark;
$button_disabled_backgroundcolor: #AAAAAA;
$button_disabled_textcolor:       #666666;
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
/*
$button-textcolor: #FFFFFF; //$color-text-dark;
$button_disabled_backgroundcolor: #AAAAAA;
$button_disabled_textcolor:       #666666;
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
/*

Button:
- link
- outline
- solid


Defining a button
    a.wh-form-button
    button.wh-form-button
    *.wh-form-button

Layout modifyers:
    .wh-form-button--outline - Overrides the default solid style of the button
    .wh-form-button--solid

Communicating intent (icons):
    .wh-form__button--backward
    .wh-form__button--forward

*/
a.simplebutton:focus,
.wh-form__button:focus {
  outline: 2px solid #01689b;
  outline-offset: 2px;
  -moz-outline-radius: 2px;
}

.wh-form__button {
  border: 0 none;
  outline: none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font-family: "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  font-size: 20px;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  height: 46px;
  padding: 0 30px 0 30px;
  border-radius: 0px;
  transition: background-color 0.3s;
  cursor: pointer;
}
@media (max-width: 640px) {
  .wh-form__button {
    padding: 0 20px 0 20px;
  }
}

.wh-form__button:not(.wh-form__button--solid),
.wh-form__button--outline {
  background-color: transparent;
  border: 1px solid #01689b;
  color: #01689b;
}
.wh-form__button:not(.wh-form__button--solid):hover,
.wh-form__button--outline:hover {
  background-color: #01689b;
  color: #FFFFFF;
  text-decoration: none;
}
.wh-form__button:not(.wh-form__button--solid) .wh-form__button--disabled,
.wh-form__button--outline .wh-form__button--disabled {
  border: 1px solid #999999;
  color: #FFFFFF;
  cursor: default;
}

.wh-form__buttongroup .wh-form__button {
  background-color: transparent;
  border: 1px solid #01689b;
  color: #01689b;
}
.wh-form__buttongroup .wh-form__button:hover {
  background-color: #01689b;
  color: #FFFFFF;
  text-decoration: none;
}
.wh-form__buttongroup .wh-form__button .wh-form__button--disabled {
  border: 1px solid #999999;
  color: #FFFFFF;
  cursor: default;
}

.wh-form__buttongroup .wh-form__button--next,
.wh-form__buttongroup .wh-form__button--submit,
.filterform__submit {
  background-color: #01689b;
  color: #FFFFFF;
  border: 0;
}
.wh-form__buttongroup .wh-form__button--next:hover,
.wh-form__buttongroup .wh-form__button--submit:hover,
.filterform__submit:hover {
  background-color: #01496d;
  color: #FFFFFF;
  text-decoration: none;
}
.wh-form__buttongroup .wh-form__button--next .wh-form__button--disabled,
.wh-form__buttongroup .wh-form__button--submit .wh-form__button--disabled,
.filterform__submit .wh-form__button--disabled {
  background-color: #999999;
  color: #FFFFFF;
  cursor: default;
}

.wh-form__button.wh-form__button--solid,
.wh-form__button.wh-form__uploadfieldselect {
  background-color: #01689b;
  color: #FFFFFF;
  border: 0;
}
.wh-form__button.wh-form__button--solid:hover,
.wh-form__button.wh-form__uploadfieldselect:hover {
  background-color: #01496d;
  color: #FFFFFF;
  text-decoration: none;
}
.wh-form__button.wh-form__button--solid .wh-form__button--disabled,
.wh-form__button.wh-form__uploadfieldselect .wh-form__button--disabled {
  background-color: #999999;
  color: #FFFFFF;
  cursor: default;
}

.wh-form .wh-form__button {
  height: 48px;
}
@media (max-width: 500px) {
  .wh-form .wh-form__button {
    height: 44px;
  }
}

.wh-form__navbuttons .wh-form__button {
  height: 46px;
}

.wh-form__button--arrow::before,
.spc-textbutton--arrow::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f054";
  font-size: 13px;
  margin-right: 10px;
}

.wh-form__button--backward::before,
.wh-form__button--previous::before,
.spc-textbutton--backward::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f060";
  font-size: 13px;
}

.wh-form__button--backward::before,
.spc-textbutton--backward::before {
  margin-right: 15px;
}

.wh-form__button--previous::before {
  margin-right: auto;
}

.spc-textbutton .wh-form__button--backward::before {
  margin-right: 15px;
}

.wh-form__button--forward::after,
.wh-form__button--next::after,
.wh-form__button--submit::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f054";
  font-size: 13px;
  vertical-align: middle;
  margin-left: 35px;
}

.spc-textbutton--forward::before,
.widget-news__morelink::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f054";
  font-size: 13px;
  vertical-align: middle;
  margin-right: 15px;
}

.widget-news__morelink::before {
  font-size: 17px;
}

.spc-textbutton .wh-form__button--forward::after {
  margin-left: 15px;
}

.widget-cta .wh-form__button--forward::after {
  margin-left: 70px;
}

.wh-form__button--next,
.wh-form__button--submit {
  justify-content: space-between;
}

.wh-form__button--purple {
  border-color: #56145F;
  color: #56145F;
}

/*
  .widget--background-darkblue .wh-form__button.wh-form__button
, .widget--background-orange   .wh-form__button.wh-form__button
, .widget--background-red      .wh-form__button.wh-form__button
, .page-header__slidecontent   .wh-form__button.wh-form__button:not(:hover)
{
  background-color: transparent;
  border-color: #FFFFFF;
  color: #FFFFFF;
}

.widget--background-darkblue .wh-form__button.wh-form__button:hover
{
  background-color: #FFFFFF;
  color: $themecolor-darkblue;
}

.widget--background-red .wh-form__button.wh-form__button:hover
{
  background-color: #FFFFFF;
  color: $themecolor-red;
}

.widget--background-orange .wh-form__button.wh-form__button:hover
{
  background-color: #FFFFFF;
  color: $themecolor-orange;
}
*/
form.wh-form--submitting .wh-form__button--submit {
  opacity: 0.5;
  transition: none;
  pointer-events: none;
}

.spc-textbutton {
  color: #01689b;
  text-decoration: underline;
}

.spc-textbutton:hover {
  color: #01496d;
  text-decoration: none;
}

/*
.spc-textbutton.wh-form__button--blue
{
  color: $themecolor-blue;
}
.spc-textbutton.wh-form__button--blue:hover
{
  color: $themecolor-blue-hover;
}

.spc-textbutton.wh-form__button--pink
{
  color: $themecolor-pink;
}
.spc-textbutton.wh-form__button--darkblue:hover
{
  color: $themecolor-pink-hover;
}

.spc-textbutton.wh-form__button--green
{
  color: $themecolor-green;
}
.spc-textbutton.wh-form__button--green:hover
{
  color: $themecolor-green-hover;
}

.spc-textbutton.wh-form__button--purple
{
  color: $themecolor-purple;
}
.spc-textbutton.wh-form__button--purple:hover
{
  color: $themecolor-purple-hover;
}



.wh-form__button--outline.wh-form__button--blue
{
  border-color: $themecolor-blue;
  color: $themecolor-blue;
}
.wh-form__button--outline.wh-form__button--blue:hover
{
  background-color: $themecolor-blue;
}


.wh-form__button--outline.wh-form__button--pink
{
  border-color: $themecolor-pink;
  color: $themecolor-pink;
}
.wh-form__button--outline.wh-form__button--pink:hover
{
  background-color: $themecolor-pink;
}


.wh-form__button--outline.wh-form__button--green
{
  border-color: $themecolor-green;
  color: $themecolor-green;
}
.wh-form__button--outline.wh-form__button--green:hover
{
  background-color: $themecolor-green;
}


.wh-form__button--outline.wh-form__button--purple
{
  border-color: $themecolor-purple;
  color: $themecolor-purple;
}
.wh-form__button--outline.wh-form__button--purple:hover
{
  background-color: $themecolor-purple;
}






.wh-form__button--solid.wh-form__button--blue
{
  background-color: $themecolor-blue;
}
.wh-form__button--solid.wh-form__button--blue:hover
{
  background-color: $themecolor-blue-hover;
}


.wh-form__button--solid.wh-form__button--pink
{
  background-color: $themecolor-pink;
}
.wh-form__button--solid.wh-form__button--pink:hover
{
  background-color: $themecolor-pink-hover;
}


.wh-form__button--solid.wh-form__button--green
{
  background-color: $themecolor-green;
}
.wh-form__button--solid.wh-form__button--green:hover
{
  background-color: $themecolor-green-hover;
}


.wh-form__button--solid.wh-form__button--purple
{
  background-color: $themecolor-purple;
}

.wh-form__button--solid.wh-form__button--purple:hover
{
  background-color: $themecolor-purple-hover;
}
*/
/*
.widget-cta
.widget-richcontent
*/
.widget--background .wh-form__button.wh-form__button,
.widget--withdarkbg .wh-form__button.wh-form__button {
  background-color: transparent;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
}
.widget--background .wh-form__button.wh-form__button:hover,
.widget--withdarkbg .wh-form__button.wh-form__button:hover {
  background-color: #FFFFFF;
  color: #FFFFFF;
  text-decoration: none;
}
.widget--background .wh-form__button.wh-form__button .wh-form__button--disabled,
.widget--withdarkbg .wh-form__button.wh-form__button .wh-form__button--disabled {
  border: 1px solid #999999;
  color: #FFFFFF;
  cursor: default;
}

.widget--background .wh-form__button.wh-form__button:hover,
.widget--withdarkbg .wh-form__button.wh-form__button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.widget--background .spc-textbutton,
.widget--withdarkbg .spc-textbutton {
  color: #FFFFFF;
}