/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
.firstvisit {
  display: none;
}

.showfirstvisitpanel main > * {
  display: none !important;
}
.showfirstvisitpanel main > .firstvisit {
  display: block !important;
}

.firstvisit__button {
  margin-top: 30px;
}

@media (max-width: 500px) {
  .firstvisit__button__desktoptext {
    display: none;
  }
}
@media (min-width: 501px) {
  .firstvisit__button__mobiletext {
    display: none;
  }
}