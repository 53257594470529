/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
/*
$button-textcolor: #FFFFFF; //$color-text-dark;
$button_disabled_backgroundcolor: #AAAAAA;
$button_disabled_textcolor:       #666666;
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
/*
From JZOJP but:

- datetime style tweaks

- .wh-form.wh-styledinput wrapper changed to .wh-form
   (form layout should NOT be dependant on opting-in to the input styling)
*/
.wh-form__label {
  font-size: 20px;
}
@media (max-width: 500px) {
  .wh-form__label {
    font-size: 15px;
  }
}

.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

.rpc__busyoverlay {
  display: none;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
html.dompack--busymodal .rpc__busyoverlay {
  display: block;
}
.rpc__busyoverlay .spinner {
  display: block;
  position: absolute;
  color: #fff;
  font-size: 80px;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  opacity: 0.8;
}

.wh-form__richtext .centercontent {
  padding-right: 0;
  padding-left: 0;
}

.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  margin-bottom: 22px;
}
@media (max-width: 500px) {
  .wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
    font-size: 15px;
  }
}

.wh-form__fieldgroup.wh-form__fieldgroup--nomargin:not(.wh-form__fieldgroup--hidden) {
  margin-bottom: 0;
}

.wh-form {
  /*
  // Mark: fix om te voorkomen dat lange select options de layout oprekken
  select:not(.wh-rtd__toolbarstyle)
  {
    flex: 1 1 auto;
    width: 0;
  }
  */
  /*
  FILL WHOLE AREA

    .wh-form__buttongroup.wh-form__navbuttons > *
    {
      justify-content: center;
      flex: 1 0 auto;
    }
  */
}
.wh-form h3 {
  font-size: 21px;
}
.wh-form .wh-form__fieldgroup--required > .wh-form__label::after,
.wh-form .wh-form__fieldgroup--required .wh-form__label:empty + .wh-form__fields .wh-form__optionlabel::after {
  display: inline-block;
  content: "*";
}
.wh-form .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
}
.wh-form .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
}
.wh-form .wh-form__optionlabel, .wh-form .wh-form__subfield > label {
  padding-right: 15px;
}
.wh-form .wh-form__label:empty::after {
  display: none;
}
.wh-form .wh-form__fieldgroup > .wh-form__label {
  flex: 0 0 150px;
  max-width: 150px;
  min-width: 150px;
}
.wh-form .wh-form__fieldgroup > .wh-form__fields {
  max-width: calc(100% - 150px);
}
.wh-form .wh-form__fieldgroup--richtext > .wh-form__fields {
  max-width: 100%;
}
@media (max-width: 800px) {
  .wh-form .wh-form__fieldgroup {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
  }
  .wh-form .wh-form__fieldgroup > .wh-form__label {
    padding-bottom: 3px;
  }
  .wh-form .wh-form__fieldgroup .wh-form__subfield {
    flex-wrap: wrap;
  }
  .wh-form .wh-form__fieldgroup > .wh-form__label, .wh-form .wh-form__fieldgroup > .wh-form__fields {
    flex: none;
    max-width: calc(100vw - 40px);
    min-width: 0;
  }
  .wh-form .wh-form__fields {
    width: 100%;
  }
}
.wh-form select + .wh-form__subfield > label,
.wh-form .wh-form__pulldown-styled + .wh-form__subfield > label {
  padding-left: 10px;
}
.wh-form input, .wh-form select:not(.wh-rtd__toolbarstyle),
.wh-form .wh-form__imgedit,
.wh-form .wh-form__dateinputgroup,
.wh-form .wh-form__timeinputgroup {
  max-width: 100%;
  flex: 1;
}
.wh-form input:focus, .wh-form select:not(.wh-rtd__toolbarstyle):focus,
.wh-form .wh-form__imgedit:focus,
.wh-form .wh-form__dateinputgroup:focus,
.wh-form .wh-form__timeinputgroup:focus {
  outline: none;
  box-shadow: 0 0 3px rgb(0, 150, 255);
}
.wh-form textarea {
  padding-top: 11px;
  padding-bottom: 11px;
}
.wh-form textarea {
  min-height: 140px;
}
.wh-form .wh-rtd__editor {
  width: 100%;
  min-height: 200px;
}
.wh-form .wh-rtd__editor .wh-rtd__stylescope {
  padding: 5px 8px;
}
.wh-form .wh-rtd__editor .wh-rtd-toolbar select {
  height: 23px;
  border-color: #aaa;
  border-radius: 1px;
  font-size: 15px;
  padding: 0 10px;
  min-height: 22px;
}
.wh-form .wh-form__fieldgroup--error input, .wh-form .wh-form__fieldgroup--error select,
.wh-form .wh-form__fieldgroup--error textarea,
.wh-form .wh-form__fieldgroup--error .wh-form__imgedit,
.wh-form .wh-form__fieldgroup--error .wh-form__dateinputgroup,
.wh-form .wh-form__fieldgroup--error .wh-form__timeinputgroup {
  background-color: #FFEAEA;
  border-color: #CD202C;
  color: #FF0000;
}
.wh-form .wh-form__error {
  display: none;
  flex: none;
  width: 100%; /* force error text below item */
  color: #FF0000;
  padding: 8px 0 0;
}
.wh-form .wh-form__fieldgroup--error .wh-form__error {
  display: block;
}
.wh-form .wh-form__fieldgroup--radiogroup .wh-form__fieldline,
.wh-form .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  align-items: baseline;
}
.wh-form .wh-form__fieldgroup--radiogroup .wh-form__fieldline + .wh-form__fieldline,
.wh-form .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 10px;
}
.wh-form .wh-form__optiondata > * {
  padding-left: 15px;
}
.wh-form .wh-form__optiondata--horizontal {
  margin-right: 30px;
  width: 100%;
}
.wh-form .wh-form__optiondata--horizontal:last-child {
  margin-right: 0;
}
.wh-form .wh-form__fieldgroup--horizontal > .wh-form__fields {
  margin-left: -20px;
}
.wh-form .wh-form__fieldgroup--horizontal > .wh-form__fields > .wh-form__fieldline {
  padding-left: 20px;
}
.wh-form .wh-form__fieldgroup--horizontal .wh-form__fieldline--nowrap {
  margin-top: 10px;
}
.wh-form .wh-form__buttongroup {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.wh-form .wh-form__buttongroup .wh-form__button {
  min-width: 200px;
}
.wh-form .wh-form__buttongroup .wh-form__button--next, .wh-form .wh-form__buttongroup .wh-form__button--submit {
  margin-left: auto;
}