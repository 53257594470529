/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
/*
$button-textcolor: #FFFFFF; //$color-text-dark;
$button_disabled_backgroundcolor: #AAAAAA;
$button_disabled_textcolor:       #666666;
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
.filterform__fields #search-text {
  flex: 0 0 100%;
}
.filterform__fields .multiselect {
  width: 100%;
}
.filterform__fields .multiselect__panel__item + .multiselect__panel__item {
  margin-top: 15px;
}
.filterform__fields .multiselect__panel__item input + label {
  margin-right: 15px;
}

.filterform__label {
  flex: 0 0 125px;
  margin-right: 25px;
}

.filterform__fields {
  flex: 0 0 calc(100% - 125px - 25px);
  display: flex;
  align-items: center;
}

.filterform__fields .filterform__label {
  width: auto;
  flex: none;
  padding: 0 15px;
}

.wobsearch__filters {
  background-color: #F3F3F3;
}

.searchpage--noresults .resultscount {
  display: none;
}

.wobsearchform__bottombar {
  display: flex;
  align-items: center;
  width: 100%;
  grid-column: 2;
  justify-content: space-between;
}

.filterform__submit.filterform__submit.filterform__submit {
  flex: 1 1 auto;
  max-width: 230px;
  height: 48px;
  justify-content: space-between;
}

.filterform__submit::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f002";
  font-size: 20px;
  color: #FFFFFF;
  margin-left: 20px;
}

.wobsearch__filters__content {
  max-width: 783px;
  padding-left: 15px;
  padding-right: 15px;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
}

.searchpage--searching .wh-form::after {
  background: rgba(243, 243, 243, 0.5);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.resultsheader {
  margin-top: 35px;
  max-width: 783px;
  padding-left: 15px;
  padding-right: 15px;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.resultscontainer {
  margin-top: 20px;
  max-width: 783px;
  padding-left: 15px;
  padding-right: 15px;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.resultscount,
.resultscontainer__noresults {
  font-size: 20px;
  color: #535353;
}

.resultscount__num {
  font-size: 30px;
  color: #000000;
}

.resultscount + .wobsearch__filtertags {
  margin-top: 15px;
}

.wobsearch__filtertags:empty {
  margin-top: 0;
}

.wobsearch__filtertags {
  width: 100%;
  margin-left: -15px;
  margin-bottom: -5px;
}

.searchfilters__filter {
  cursor: pointer;
  background-color: #F3F3F3;
  border: none;
  color: #000000;
  border-radius: 3px;
  font: 16px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  display: inline-flex;
  align-items: center;
  height: 34px;
  padding-left: 12px;
  padding-right: 20px;
  margin-left: 10px;
  margin-bottom: 5px;
  padding-bottom: 0;
  padding-top: 0;
}
.searchfilters__filter::after {
  margin-left: 14px;
  content: "\ea05";
  font-family: "RO Icons";
  font-size: 0.7em;
}
.searchfilters__filter:hover {
  text-decoration: line-through;
  background-color: #e6e6e6;
}
.searchfilters__filter--nodelete {
  cursor: default;
}
.searchfilters__filter--nodelete::after {
  display: none;
}
.searchfilters__filter--nodelete:hover {
  text-decoration: none;
  background-color: #f3f3f3;
}

.searchfilters__clearbutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  font: 16px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  color: #535353;
  text-decoration: underline;
  margin-left: 18px;
}

.searchfilters__clearbutton:hover {
  color: #01689b;
}

.searchfilters__toggle {
  margin-left: 20px;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: #535353;
  font: 18px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  display: inline-flex;
  align-items: center;
  height: 34px;
  padding-left: 12px;
  padding-right: 20px;
  padding-right: 18px;
  cursor: pointer;
  white-space: nowrap;
}

@media (max-width: 500px) {
  .searchfilters__toggle {
    font-size: 15px;
    margin-right: -10px;
    padding-right: 10px;
  }
}
.searchfilters__toggle:hover {
  background-color: #F3F3F3;
  border-radius: 3px;
}

.searchfilters__toggle__content {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr min-content;
}

.toggle-hide {
  grid-area: 1/1;
  visibility: hidden;
}

.toggle-show {
  grid-area: 1/1;
}

.searchfilters__toggle::after {
  grid-area: 2/1;
  margin-left: 12px;
  content: "\ea24";
  font-family: "RO Icons";
  font-size: 12px;
  color: #000000;
}

.searchpage--showfilters .toggle-hide {
  visibility: visible;
}
.searchpage--showfilters .toggle-show {
  visibility: hidden;
}
.searchpage--showfilters .searchfilters__toggle::after {
  transform: scaleY(-1);
}

@media (max-width: 500px) {
  html:not(.searchpage--showfilters) .filterform__label[for=search-operator],
  html:not(.searchpage--showfilters) .filterform__label[for=search-operator] + .filterform__fields,
  html:not(.searchpage--showfilters) .filterform__label[for=search-publishedafter],
  html:not(.searchpage--showfilters) .filterform__label[for=search-publishedafter] + .filterform__fields,
  html:not(.searchpage--showfilters) .filterform__label[for=search-department],
  html:not(.searchpage--showfilters) .filterform__label[for=search-department] + .filterform__fields,
  html:not(.searchpage--showfilters) .filterform__label[for=search-minister],
  html:not(.searchpage--showfilters) .filterform__label[for=search-minister] + .filterform__fields,
  html:not(.searchpage--showfilters) .filterform__label[for=search-subjects],
  html:not(.searchpage--showfilters) .filterform__label[for=search-subjects] + .filterform__fields,
  html:not(.searchpage--showfilters) .filterform__label[for=search-kind],
  html:not(.searchpage--showfilters) .filterform__label[for=search-kind] + .filterform__fields {
    display: none;
  }
}
@media (min-width: 501px) {
  html:not(.searchpage--showfilters) .filterform__label[for=search-operator],
  html:not(.searchpage--showfilters) .filterform__label[for=search-operator] + .filterform__fields,
  html:not(.searchpage--showfilters) .filterform__label[for=search-publishedafter],
  html:not(.searchpage--showfilters) .filterform__label[for=search-publishedafter] + .filterform__fields,
  html:not(.searchpage--showfilters) .filterform__label[for=search-department],
  html:not(.searchpage--showfilters) .filterform__label[for=search-department] + .filterform__fields,
  html:not(.searchpage--showfilters) .filterform__label[for=search-minister],
  html:not(.searchpage--showfilters) .filterform__label[for=search-minister] + .filterform__fields,
  html:not(.searchpage--showfilters) .filterform__label[for=search-subjects],
  html:not(.searchpage--showfilters) .filterform__label[for=search-subjects] + .filterform__fields,
  html:not(.searchpage--showfilters) .filterform__label[for=search-kind],
  html:not(.searchpage--showfilters) .filterform__label[for=search-kind] + .filterform__fields {
    visibility: hidden; /* to hide visually and from screenreaders */
    height: 0;
    /* margin: 0; <-- DON'T reset margins */
    padding: 0;
    overflow: hidden;
  }
  html:not(.searchpage--showfilters) .filterform {
    /*
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    */
    display: grid;
    grid-template-columns: max-content auto;
    grid-row-gap: 0;
  }
  html:not(.searchpage--showfilters) .filterform .wobsearchform__bottombar {
    margin-top: 22px;
  }
}
.wh-form {
  position: relative;
}
.searchpage--searching .wh-form::after {
  background: rgba(255, 255, 255, 0.5);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.searchresults__sorting {
  margin-top: 10px;
  border-top: 1px solid #BDBDBD;
  padding-top: 20px;
  color: #535353;
  font: 20px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
}

.sortoption {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  font: inherit;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.sortoption--current {
  font-weight: bold;
  color: #01689b;
}

.searchresults__rssfeed {
  margin-top: 30px;
  margin-bottom: 30px;
}
.searchresults__rssfeed a {
  display: inline-flex;
  align-items: center;
  font: 20px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  font-weight: bold;
  color: #01689b;
}
.searchresults__rssfeed a:hover {
  text-decoration: none;
}

html.searchpage--filtersactive .searchresults__rssfeed {
  display: none;
}

.searchresults__rssfeed-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #01689b;
  margin-right: 0.75rem;
}
.searchresults__rssfeed-icon::before {
  display: block;
  content: url("data:image/svg+xml,%3Csvg id='icon-bell' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85 100'%3E%3Cpath d='M85,69.28a3.78,3.78,0,0,1-1.26,2.46,3.82,3.82,0,0,1-2.64,1h-77a4,4,0,0,1-2.7-1A3.63,3.63,0,0,1,0,69.34a4.06,4.06,0,0,1,.66-2.75l6.6-9.94A39.27,39.27,0,0,0,11.37,46l4.92-18.57a26.54,26.54,0,0,1,17.87-19V8.26A7.85,7.85,0,0,1,36.62,2.4a8.4,8.4,0,0,1,11.76,0,7.85,7.85,0,0,1,2.46,5.86v.12a26.54,26.54,0,0,1,17.87,19L73.63,46a36,36,0,0,0,4.08,10.54l6.6,10.06A3.74,3.74,0,0,1,85,69.28Zm-57,14.32H57.08l.24,1.67a13.81,13.81,0,0,1-2,7.31A16,16,0,0,1,49.88,98a14.43,14.43,0,0,1-14.76,0,15.91,15.91,0,0,1-5.4-5.39,13.81,13.81,0,0,1-2-7.31Z' style='fill: %23fff'/%3E%3C/svg%3E");
  width: 18.7px;
  height: 22px;
}

.searchresults__results {
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
}

.searchresult {
  position: relative;
  text-decoration: none;
}

@media (max-width: 500px) {
  .filterform .filterform__label {
    display: block;
    margin-top: 15px;
    font: 15px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
    margin-bottom: 2px;
  }
  .filterform .filterform__fields .filterform__label {
    margin-top: 0;
  }
  .filterform .wobsearchform__bottombar {
    margin-top: 15px;
  }
  .filterform .filterform__submit.filterform__submit.filterform__submit {
    width: 154px;
    height: 44px;
    font-size: 18px;
  }
  .searchfilters__filler {
    flex: 1 0 0px;
  }
  .searchfilters__clearbutton {
    font: 14px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  }
  .searchresults__sorting {
    font: 15px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  }
  .searchresults__rssfeed a {
    font: bold 15px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  }
}
@media (min-width: 501px) {
  .filterform {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    display: grid;
    grid-template-columns: max-content auto;
    grid-row-gap: 22px;
    align-items: center;
  }
}
/*
@media (min-width: 992px)
.article .results:not(.gallery-result) a:before, .results:not(.gallery-result) a:before {
    height: 28px;
}
@media (min-width: 768px)
.article .results:not(.gallery-result) a:before, .results:not(.gallery-result) a:before {
    height: 25.2px;
}
@media (min-width: 992px)
.article .results:not(.gallery-result) a:before, .results:not(.gallery-result) a:before {
    width: 8.4px;
}
@media (min-width: 768px)
.article .results:not(.gallery-result) a:before, .results:not(.gallery-result) a:before {
    width: 7.56px;
}
*/
a.searchresult {
  color: #000000;
  display: block;
  margin-left: 48px;
  padding: 0;
  position: relative;
  z-index: 0;
}
a.searchresult--showdocuments::after {
  background-color: #f3f3f3;
  bottom: 0;
  content: "";
  left: -64px;
  position: absolute;
  right: -16px;
  top: -16px;
  z-index: -1;
}

.searchresult__toggle {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: block;
  height: 24px;
  left: -48px;
  position: absolute;
  top: 1px;
  width: 24px;
}
.searchresult:not(.searchresult--hasdocuments) .searchresult__toggle {
  cursor: default;
}

.searchresult__toggle::before {
  color: #01689b;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f055";
  font-size: 24px;
}
.searchresult--showdocuments .searchresult__toggle::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f056";
}
.searchresult:not(.searchresult--hasdocuments) .searchresult__toggle::before {
  content: "\ea25";
  font-family: "RO Icons";
  font-size: 15px;
  top: 7px;
}

/*
a:visited::before, a:visited::after {
    color: #848;
}
*/
a.searchresult:focus,
a.searchresult__doclink:focus,
.searchresult__toggle:focus,
button.searchfilters__toggle:focus,
button.searchfilters__filter:focus {
  outline: 2px dotted #000;
  z-index: 1010;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 2px #fff;
  box-shadow: 0 0 0 2px #fff;
}

.searchresult + .searchresult,
.searchresult__item + .searchresult__item {
  margin-top: 20px;
}

.searchresult__item--hasdocuments + .searchresult__item {
  margin-top: 25px;
}

.searchresult__title {
  font: 23px/28px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  font-weight: bold;
  color: #01689b;
  text-decoration: underline;
}

.searchresult__documents {
  display: none;
  padding-bottom: 20px;
  padding-left: 64px;
  position: relative;
  z-index: 0;
}
.searchresult__documents::after {
  background-color: #f3f3f3;
  bottom: 0;
  content: "";
  left: -16px;
  position: absolute;
  right: -16px;
  top: -20px;
  z-index: -1;
}
.searchresult--showdocuments + .searchresult__documents {
  display: block;
}

.searchresult__document {
  margin-top: 20px;
  z-index: 0;
}

.searchresult__doclink {
  color: #000000;
  display: block;
  font: 18px/24px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  text-decoration: none;
}

.searchresult__doctitle {
  font: 20px/28px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  color: #01689b;
  text-decoration: underline;
}

.searchresult__description {
  margin-top: 5px;
  font: 18px/24px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  /*
  This work on:
  - Chrome 14+  (sep 2011)
  - Edge 17+    (apr 2018)
  - Safari 5+   (mar 2012)
  - iOS 5+      (mar 2012)
  - Firefox 68+ (jul 2019)

  Also see
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.searchresult__docdescription {
  font: 18px/24px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
}

.searchresult__metadata,
.searchresult__docmetadata {
  margin-top: 3px;
  font: 15px/24px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  color: #535353;
}

.searchresult--match {
  font-weight: bold;
}

@media (min-width: 415px) and (max-width: 1024px) {
  .searchresult__title,
  .searchresult__doctitle {
    font: 20px/25px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  }
  .searchresult__description,
  .searchresult__docdescription {
    font: 16px/22px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  }
  .searchresult__metadata,
  .searchresult__docmetadata {
    font: 14px/22px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  }
}
@media (max-width: 414px) {
  .resultscount {
    font-size: 15px;
  }
  .searchfilters__filter {
    font-size: 14px;
    height: 30px;
  }
  .resultscount__num {
    font-size: 18px;
  }
  .searchresult + .searchresult {
    margin-top: 15px;
  }
  .searchresult__title,
  .searchresult__doctitle {
    font: 16px/22px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  }
  .searchresult__description,
  .searchresult__docdescription {
    font: 13px/22px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  }
  .searchresult__metadata,
  .searchresult__docmetadata {
    font: 13px/24px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  }
}
.searchresult__description.searchresult__description > * {
  margin: 0;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .filterform__fields {
    flex: 0 0 auto;
    width: calc(100% - 175px);
    margin-bottom: 20px;
  }
  .filterform > .filterform__label {
    margin-bottom: 20px;
  }
  .filterform__submit.filterform__submit.filterform__submit {
    max-width: none;
    flex: none;
    width: 230px;
  }
  .wh-form__dateinputgroup__line.wh-form__dateinputgroup__line.wh-form__dateinputgroup__line,
  .wh-form__timeinputgroup__line.wh-form__timeinputgroup__line.wh-form__timeinputgroup__line {
    width: 45px !important;
  }
}