@charset "UTF-8";
/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
.searchresults__pages {
  margin-top: 55px;
  display: flex;
  border-top: 4px solid #01689b;
}

.searchresults__pages a:focus,
.searchresults__pages button:focus {
  outline: 2px dotted #000;
  z-index: 1010;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 2px #fff;
  box-shadow: 0 0 0 2px #fff;
}

.pagelink--page {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  font: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 44px;
  font: 23px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  color: #01689b;
}

.pagelink--ellipsis {
  display: flex;
  align-items: center;
}

.pagelink--ellipsis::before {
  content: "…";
}

.pagelink--current {
  background-color: #01689b;
  color: #FFFFFF;
}

.pagelink--previous,
.pagelink--next {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  font: inherit;
  cursor: pointer;
  width: auto;
  display: flex;
  align-items: center;
}

.pagelink--previous {
  color: #01689b;
  font-size: 20px;
  margin-right: 15px;
  padding-right: 15px;
}

.pagelink--previous::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f323";
  font-size: 14px;
  margin-right: 14px;
}

.pagelink--next {
  color: #01689b;
  font-size: 20px;
  padding-left: 15px;
  margin-left: auto;
}

.pagelink--next::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f324";
  font-size: 12px;
  margin-left: 14px;
}

.pagelink:not(.pagelink--current):hover {
  text-decoration: underline;
}

@media (max-width: 500px) {
  .searchresults__pages {
    margin-top: 30px;
    border-top: 3px solid #01689b;
  }
  .pagelink--page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 29px;
    font: 16px "RO Sans", "Alegreya Sans", Calibri, sans-serif;
  }
  .pagelink--next,
  .pagelink--previous {
    font-size: 0;
  }
}