@charset "UTF-8";
input[type=text].error + button.ui-datepicker-trigger, input[type=email].error + button.ui-datepicker-trigger, input[type=password].error + button.ui-datepicker-trigger, input[type=file].error + button.ui-datepicker-trigger, input[type=date].error + button.ui-datepicker-trigger, textarea.error + button.ui-datepicker-trigger {
  border: 2px solid #c63c2c;
  border-left-width: 0;
}

/*
button.ui-datepicker-trigger{
    position:relative;
    overflow:hidden;
    width:2.5rem;
    height:2.5rem;
    min-height:2.5rem;
    padding:0;
    cursor:pointer;
    -webkit-transition:background-color 0.3s ease;
    transition:background-color 0.3s ease;
    text-indent:-9999px;
    width:44px;
    height:44px;
    margin-left:-44px;
    background-color:transparent
}
button.ui-datepicker-trigger:after{
    content:"";
    font-family:"RO Icons";
    font-size:inherit;
    font-weight:normal;
    line-height:inherit;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    position:absolute;
    top:50%;
    left:0;
    width:100%;
    -webkit-transition:color 0.3s ease;
    transition:color 0.3s ease;
    -webkit-transform:translateY(-50%);
    transform:translateY(-50%);
    text-align:center;
    text-indent:0
}
button.ui-datepicker-trigger:after{
    color:#01689b
}
button.ui-datepicker-trigger:hover{
    background-color:#01496d
}
button.ui-datepicker-trigger:hover:after{
    color:#fff
}
*/
[dir=rtl] button.ui-datepicker-trigger {
  margin-left: 0;
  margin-right: -44px;
}

.ui-datepicker {
  z-index: 1050 !important;
  max-width: calc(100% - 2rem);
  margin-top: 5px;
  margin-left: -1rem;
  background-color: #e5f0f9;
  -webkit-box-shadow: 0 0 10px 1px dimgray;
  box-shadow: 0 0 10px 1px dimgray;
}

.ui-datepicker .ui-datepicker-header {
  background-color: #01689b;
  display: flex;
  align-items: center;
  position: relative;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-title {
  position: relative;
  display: block;
  float: left;
  width: calc(100% - 88px);
  text-align: center;
  font-weight: bold;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-title .proSelect {
  float: left;
  width: calc(50% - 0.5rem);
  margin-right: 0.5rem;
  margin-left: 0;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-title .proSelect select {
  min-width: 150px;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-title .proSelect + .proSelect {
  left: 0.5rem;
  margin-right: 0;
}

.ui-datepicker .ui-datepicker-header .ui-icon-circle-triangle-w {
  position: relative;
  overflow: hidden;
  width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  padding: 0;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  text-indent: -9999px;
  background-color: #01689b;
  float: left;
}

.ui-datepicker .ui-datepicker-header .ui-icon-circle-triangle-w:after {
  content: "\ea25";
  font-family: "RO Icons";
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  text-indent: 0;
}

.ui-datepicker .ui-datepicker-header .ui-icon-circle-triangle-w:after {
  color: #fff;
}

.ui-datepicker .ui-datepicker-header .ui-icon-circle-triangle-w:hover {
  background-color: #01496d;
}

.ui-datepicker .ui-datepicker-header .ui-icon-circle-triangle-w:hover:after {
  color: #fff;
}

.ui-datepicker .ui-datepicker-header .ui-icon-circle-triangle-w:after {
  -webkit-transform: translateY(-50%) rotate(-180deg);
  transform: translateY(-50%) rotate(-180deg);
}

.ui-datepicker .ui-datepicker-header .ui-icon-circle-triangle-e {
  position: relative;
  overflow: hidden;
  width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  padding: 0;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  text-indent: -9999px;
  background-color: #01689b;
  float: right;
}

.ui-datepicker .ui-datepicker-header .ui-icon-circle-triangle-e:after {
  content: "\ea25";
  font-family: "RO Icons";
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  text-indent: 0;
}

.ui-datepicker .ui-datepicker-header .ui-icon-circle-triangle-e:after {
  color: #fff;
}

.ui-datepicker .ui-datepicker-header .ui-icon-circle-triangle-e:hover {
  background-color: #01496d;
}

.ui-datepicker .ui-datepicker-header .ui-icon-circle-triangle-e:hover:after {
  color: #fff;
}

.ui-datepicker .ui-datepicker-calendar {
  clear: both;
  width: 100%;
  margin-bottom: 0;
}

.ui-datepicker .ui-datepicker-calendar thead {
  background-color: #e5f0f9;
  border-bottom: 1px solid #cce0f1;
}

.ui-datepicker .ui-datepicker-calendar thead th {
  width: auto;
  padding: 0;
  text-align: center;
  border-right: 0;
  border-bottom: 1px solid #cce0f1;
  border-left: 0;
  background-color: #e5f0f9;
  font-size: 0.75em;
  font-weight: bold;
}

.ui-datepicker .ui-datepicker-calendar thead th:first-child {
  width: 45px;
}

.ui-datepicker .ui-datepicker-calendar thead th span {
  display: block;
  overflow: hidden;
  width: 30px;
  padding: 0.7em 0.4em 0.4em;
  white-space: nowrap;
}

.ui-datepicker .ui-datepicker-calendar thead th:first-child {
  display: block;
  visibility: hidden;
}

.ui-datepicker .ui-datepicker-calendar tbody td {
  width: 12%;
  padding: 0;
  text-align: center;
  border-right: 0;
  border-bottom: 1px solid #e5f0f9;
  border-left: 0;
  background-color: #fff;
}

.ui-datepicker .ui-datepicker-calendar tbody td.ui-datepicker-current-day {
  background-color: #cce0f1;
}

.ui-datepicker .ui-datepicker-calendar tbody td.ui-datepicker-current-day a {
  border-color: #cce0f1;
}

.ui-datepicker .ui-datepicker-calendar tbody td.ui-datepicker-days-cell-over a {
  border: 2px solid #cce0f1;
  background-color: #cce0f1;
}

.ui-datepicker .ui-datepicker-calendar tbody td.ui-state-hover {
  border-color: #cce0f1;
  background-color: #cce0f1;
}

.ui-datepicker .ui-datepicker-calendar tbody .ui-datepicker-week-col {
  padding: 0.55em 0.7em 0;
  color: #01689b;
  font-size: 0.8125em;
  font-style: italic;
  background: #fff;
  border-bottom: 1px solid #e5f0f9;
}

.ui-datepicker .ui-datepicker-calendar a {
  display: block;
  text-decoration: none;
  color: #000;
  border: 2px solid #fff;
}

@media (min-width: 576px) {
  .ui-datepicker .ui-datepicker-calendar a {
    padding: 0.2em 0.4em 0.3em;
  }
}
@media (min-width: 768px) {
  .ui-datepicker .ui-datepicker-calendar a {
    padding: 0.2em 0.3em 0.3em;
  }
}
.ui-datepicker .ui-datepicker-calendar a:hover {
  border: 2px solid #cce0f1;
  background-color: #cce0f1;
}

.ui-datepicker-next.ui-datepicker-next.ui-datepicker-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: auto;
}

.ui-datepicker-next.ui-datepicker-next.ui-datepicker-next {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: auto;
}

.ui-datepicker .ui-icon.ui-icon-circle-triangle-w,
.ui-datepicker .ui-icon.ui-icon-circle-triangle-e {
  left: 0;
  top: 0;
  margin: 0;
  height: 100%;
  min-height: 0;
}