button.skiplinks {
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: center;
  opacity: 0;
  pointer-events: none;
}

button.skiplinks:focus {
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}

main {
  scroll-margin-top: 35px;
}

main:focus {
  outline: 0;
}