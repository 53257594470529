@charset "UTF-8";
/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
/*
$color-theme:       #ca005d; // pink
$color-theme-hover: darken($color-theme, 5%);
*/
/*
iPhone 5/SE       - 320px
iPhone 6/7/8      - 375px
iPhone 6/7/8 Plus - 414px
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$whitespace-header-content: 50px;
$whitespace-header-content-mobile: 23px; // ADDME !!!!

$whitespace-footer-content: 80px;

$grid_spacing:              30px;

$rtdcontent_vspace: 15px;
$rtdcontent_vspace_heading: 30px;
*/
/*
$button-textcolor: #FFFFFF; //$color-text-dark;
$button_disabled_backgroundcolor: #AAAAAA;
$button_disabled_textcolor:       #666666;
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
.wh-styledinput input[type=radio],
.wh-styledinput input[type=checkbox] {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
.wh-styledinput input[type=radio] + label,
.wh-styledinput input[type=checkbox] + label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  max-width: 20px;
  flex: 0 0 20px;
  height: 20px;
  vertical-align: baseline;
  cursor: pointer;
  border: 1px solid #000000;
  background-color: #fff;
}
.wh-styledinput input[type=radio] + label::after,
.wh-styledinput input[type=checkbox] + label::after {
  color: #FFFFFF;
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  margin-top: 1px;
  content: "​";
}
.wh-styledinput input[type=radio]:focus + label,
.wh-styledinput input[type=checkbox]:focus + label {
  /*
  June 2020, the Webkit focus ring looks horrible now.
  This is due to changes in focus styling in Chrome:
  https://blog.chromium.org/2020/03/updates-to-form-controls-and-focus.html

        outline: 2px dotted #212121;
        outline-offset: 2px;
        outline: -webkit-focus-ring-color auto 5px;
  */
  /*
    outline: 2px solid $formcontrol-checkradio-checked-bordercolor;
    outline-offset: 2px;
    -moz-outline-radius: 2px;
  */
  border-color: #FFFFFF;
  box-shadow: 0 0 3px 1px #01689b;
  /*
        outline: 2px solid $formcontrol-checkradio-checked-bordercolor;
        outline-offset: 2px;
  */
}
.wh-styledinput input[type=radio]:not(:checked):focus + label,
.wh-styledinput input[type=checkbox]:not(:checked):focus + label {
  border-color: #000000;
}
.wh-styledinput input[type=radio]:checked + label,
.wh-styledinput input[type=checkbox]:checked + label {
  background-color: #01689b;
  border-color: #01689b;
}
.wh-styledinput input[type=radio]:checked + label::after,
.wh-styledinput input[type=checkbox]:checked + label::after {
  color: #FFFFFF;
}
.wh-styledinput input[type=radio]:checked:focus + label,
.wh-styledinput input[type=checkbox]:checked:focus + label {
  /*
        outline: 2px dotted #212121;
        outline-offset: 2px;
        outline: -webkit-focus-ring-color auto 5px;
  */
}
.wh-styledinput input[type=radio][disabled] + label,
.wh-styledinput input[type=checkbox][disabled] + label {
  border-color: #BFBFBF;
  cursor: default;
}
.wh-styledinput input[type=radio][disabled] + label + *,
.wh-styledinput input[type=checkbox][disabled] + label + * {
  color: #979797;
}
.wh-styledinput input[type=radio] + label {
  border-radius: 50%;
}
.wh-styledinput input[type=radio] + label::after {
  position: relative;
  top: 4px;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}
.wh-styledinput input[type=radio][disabled] + label::after {
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}
.wh-styledinput input[type=radio][disabled]:checked + label::after {
  background-color: #BFBFBF;
}
.wh-styledinput input[type=checkbox] + label {
  border-radius: 3px;
}
.wh-styledinput input[type=checkbox]:checked + label {
  border-color: #01689b;
}
.wh-styledinput input[type=checkbox]:checked + label::after {
  content: "\f00c";
}
.wh-styledinput input[type=checkbox][disabled] + label::after {
  content: "";
}
.wh-styledinput input[type=checkbox][disabled] + label {
  background-color: #F2F2F2;
}
.wh-styledinput input[type=checkbox][disabled]:checked + label::after {
  color: #BFBFBF;
}
.wh-styledinput .wh-form__fieldgroup--error input[type=checkbox]:not(:checked) + label,
.wh-styledinput .wh-form__fieldgroup--error input[type=radio]:not(:checked) + label {
  border-color: #CD202C;
}